<template>
  <div
    v-if="hasErrorMessages"
    class="text-white px-2 mt-4"
  >
    <div class="text-xs my-1 text-gray-300 select-none">
      <div class="flex justify-between pb-1 border-b border-gray-700">
        <div
          class="hover:underline cursor-pointer"
          @click.prevent="toggleMessages"
        >
          <span class="inline-block px-1 cursor-pointer text-gray-400 hover:text-blue-500">
            <fa-icon
              v-if="showMessages"
              icon="caret-down"
              size="xs"
              class="fa-fw"
            />
            <fa-icon
              v-else
              icon="caret-right"
              size="xs"
              class="fa-fw"
            />
          </span>

          <fa-icon
            icon="triangle-exclamation"
            class="fa-fw mr-1 text-red-500"
          /> Errors ({{ errorMessages.length }})
        </div>
      </div>

      <div
        v-if="showMessages"
        class="block h-48 overflow-y-scroll bg-gray-200 text-gray-800 p-1 font-mono"
      >
        <div
          v-for="msg in errorMessages"
          class="flex justify-between text-2xs block border-b border-gray-300 py-1 w-full min-w-0"
        >
          <template v-for="message in msg.messages">
            <span class="truncate">
              {{ message.message }}
            </span>
            <a
              v-if="message.resolveable"
              href="#"
              class="shrink-0 underline cursor-pointer hover:text-blue-500"
              @click.prevent="onClickResolveError(message)"
            >Resolve <fa-icon
              icon="arrow-right"
              class="fa-fw"
            /></a>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

const SceneErrorMissingResources = () => import('@modals/SceneErrorMissingResources')

export default {
  props: {
    gui: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      showMessages: true
    }
  },

  computed: {

    hasErrorMessages () {
      return this.errorMessages.length > 0
    },

    messages () {
      return this.gui.recentMessages
    },

    errorMessages () {
      return this.gui.errorMessages
    }
  },

  methods: {
    toggleMessages () {
      this.showMessages = !this.showMessages
    },

    onClickResolveError (message) {
      this.$bus.$emit('overlay:modal', {
        component: SceneErrorMissingResources, // Only error type for now
        props: {
          gui: this.gui
        },
        size: 'md'
      })
    }
  }
}
</script>
